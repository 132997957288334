<template>
  <div class="product-material">
    <vh-dialog
      title="选择商品"
      :before-close="handleClose"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :width="scene == 'coupon' ? '585px' : '800px'"
    >
      <div class="" slot="title">
        选择商品
        <span class="subTile" v-if="scene == 'coupon'">优惠券仅适用于「平台购买」方式的商品</span>
      </div>
      <div class="product-material-list">
        <div v-if="isSearch || total">
          <div class="product-material-header">
            <vh-button v-if="!isEmbed" type="primary" size="medium" round @click="toCreate">
              创建商品
            </vh-button>
            <vh-input
              class="fr"
              round
              size="medium"
              style="width: 180px"
              :maxlength="30"
              placeholder="请输入商品名称"
              v-clearEmoij
              v-model="keyword"
              @keyup.enter.native="getList($event, true)"
              clearable
              @clear="getList(true)"
            >
              <i
                class="vh-icon-search vh-input__icon"
                slot="prefix"
                @click="getList($event, true)"
              ></i>
            </vh-input>
          </div>

          <!-- 表格 -->
          <vh-table
            ref="elTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            height="340px"
            v-loadMore="moreLoadData"
            @selection-change="changeDialogCheck"
            @select-all="checkAllRow"
          >
            <vh-table-column type="selection" width="55" align="left" />
            <vh-table-column label="ID" prop="goods_id" width="80"></vh-table-column>
            <vh-table-column label="图片">
              <template slot-scope="scope">
                <div class="product-cover">
                  <img
                    :src="scope.row.cover_img"
                    v-parseImgOss="{ url: scope.row.cover_img, default: 1 }"
                    alt=""
                  />
                </div>
              </template>
            </vh-table-column>
            <vh-table-column
              label="名称"
              prop="name"
              min-width="130"
              show-overflow-tooltip
            ></vh-table-column>
            <vh-table-column label="原价" width="110">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.price !== null ? `￥${scope.row.price}` : '--' }}
                </span>
              </template>
            </vh-table-column>
            <vh-table-column label="优惠价" width="110">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.discount_price !== null ? `￥${scope.row.discount_price}` : '--' }}
                </span>
              </template>
            </vh-table-column>
            <vh-table-column label="购买方式" width="100" v-if="scene != 'coupon'">
              <template slot-scope="scope">
                {{ scope.row.buy_type | buyTypeFilter }}
              </template>
            </vh-table-column>
            <vh-table-column label="最后操作" width="140" v-if="scene != 'coupon'">
              <template slot-scope="scope">
                <p>
                  <vh-tooltip
                    effect="dark"
                    :content="generateOperator(scope.row)"
                    :disabled="generateOperator(scope.row, true).length <= 8"
                    placement="top"
                  >
                    <span>
                      {{ generateOperator(scope.row, true) }}
                    </span>
                  </vh-tooltip>
                </p>
                <p>
                  {{ scope.row.updated_date }}
                </p>
              </template>
            </vh-table-column>
            <div slot="empty">
              <null-page :nullType="'search'" v-if="!total" :height="60"></null-page>
            </div>
          </vh-table>
        </div>
        <null-page
          :nullType="'nullData'"
          :text="this.selectTotal ? '您已选择所有商品，请创建' : '您还没有商品，快来创建吧！'"
          v-else
          :height="60"
        >
          <vh-button v-if="!isEmbed" type="primary" round @click="toCreate" v-preventReClick>
            创建商品
          </vh-button>
        </null-page>
        <div class="product-material-footer" v-if="isSearch || total">
          <span class="footer-desc">
            当前选中
            <b>{{ selectProducts.length }}</b>
            个商品
          </span>
          <div>
            <vh-button round plain type="info" size="medium" @click="cancel">取消</vh-button>
            <vh-button
              v-preventReClick
              type="primary"
              round
              size="medium"
              @click="saveCheckHandle"
              :disabled="!(selectProducts && selectProducts.length > 0)"
            >
              确定
            </vh-button>
          </div>
        </div>
      </div>
    </vh-dialog>
  </div>
</template>

<script>
  import NullPage from '../../PlatformModule/Error/nullPage.vue';
  import { overHidden } from '@/utils/utils.js';
  import { buyTypeMap } from './list.vue';
  import {
    getAdminUnselectedList,
    createAdminWebinarProduct,
    getAdminProductsList
  } from '@vhcl/product';
  import { isEmbed } from '@/utils/utils';

  export default {
    name: 'ProductMaterial',
    components: {
      NullPage
    },
    props: {
      scene: {
        type: String,
        default: ''
      },
      source: {
        type: String,
        default: 'webinar'
      },
      selectedIds: {
        type: Array,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        selectTotal: 0,
        dialogVisible: false,
        total: 0,
        isSearch: false,
        isCheckAll: false,
        tableData: [],
        selectProducts: [],
        keyword: '',
        pageInfo: {
          page: 1,
          limit: 6
        },
        totalPages: 0,
        isEmbed: isEmbed()
      };
    },
    filters: {
      buyTypeFilter(val) {
        return buyTypeMap[val];
      },
      overHidden
    },
    watch: {
      selectProducts(newVal, oldVal) {
        this.$nextTick(() => {
          this.readonlyTable();
        });
      }
    },
    methods: {
      generateOperator(row, hide) {
        const str =
          row.operator_role === '主持人' ? row.operator : `${row.operator_role}-${row.operator}`;
        if (hide) {
          return overHidden(str, 8);
        }
        return str;
      },
      // 跳转到创建商品
      toCreate() {
        const { href } = this.$router.resolve({
          path: `${isEmbed() ? '/embed' : ''}/interactive/addProduct`
        });
        window.open(href, '_blank');
      },
      handleClose(done) {
        this.pageInfo.page = 1;
        this.tableData = [];
        done();
      },
      moreLoadData() {
        if (this.pageInfo.page >= this.totalPages) {
          return false;
        }
        this.pageInfo.page++;
        this.getList();
      },
      // 获取资料库-弹出框内容
      getList(isClear, isInit) {
        this.isSearch = this.keyword ? true : false;
        if (isClear) {
          this.tableData = [];
          this.pageInfo.page = 1;
        }

        let params = {
          pos: (this.pageInfo.page - 1) * this.pageInfo.limit,
          limit: this.pageInfo.limit,
          keyword: this.keyword
        };
        if (this.scene == 'coupon') {
          params.buy_type = 1;
        }
        let requestObj = null;
        if (this.source == 'webinar') {
          params.webinar_id = this.$route.params.str;
          requestObj = getAdminUnselectedList(params);
        } else {
          requestObj = getAdminProductsList(params);
        }

        requestObj
          .then(res => {
            if (res && res.code === 200) {
              if (isInit) {
                if (this.selectedIds.length) {
                  this.selectProducts = this.selectedIds;
                }
              } else {
                const diff = this.selectedIds.filter(item => {
                  return !this.tableData.some(i => {
                    return item == i.goods_id;
                  });
                });
                this.selectProducts = this.selectProducts.concat(...diff);
              }

              this.tableData.push(...(res.data.list || []));
              this.total = res.data.total;
              this.totalPages = Math.ceil(res.data.total / this.pageInfo.limit);
              if (this.isCheckAll) {
                this.$refs.elTable.toggleAllSelection();
              }
            } else {
              this.$vhMessage.error(res.msg);
            }
          })
          .finally(() => {
            this.readonlyTable();
          });
      },
      // 改变资料库-弹出框内容
      changeDialogCheck(val) {
        console.log(val);
        this.selectProducts = val.map(item => item.goods_id);
      },
      checkAllRow(selection) {
        console.log('全选与非全选', selection);
        // 只要数量大于0，即是够了全选
        this.isCheckAll = selection && selection.length > 0;
      },
      open() {
        // 历史已经选择过的数据清空
        this.tableData = [];
        this.isSearch = false;
        this.dialogVisible = true;
        this.isCheckAll = false;
        this.keyword = '';
        this.pageInfo = {
          page: 1,
          limit: 6
        };
        this.getList(true, true);
      },
      saveCheckHandle() {
        if (this.selectProducts.length + this.selectTotal > 200) {
          this.$vhMessage.error('最多选择200个商品');
          return;
        }
        if (this.scene == 'coupon') {
          const selectProductsObj = this.tableData.filter(item => {
            return this.selectProducts.some(i => {
              return item.goods_id == i;
            });
          });

          let ids = selectProductsObj.map(item => item.goods_id);
          this.$fetch(
            'checkGoods',
            {
              goods_ids: ids
            },
            {
              'Content-Type': 'application/json'
            }
          )
            .then(res => {
              if (res && res.code === 200) {
                let obj = selectProductsObj;
                if (res.data) {
                  obj = selectProductsObj.filter(e => {
                    return res.data.includes(e.goods_id);
                  });
                }

                this.$emit('success', obj);
                this.cancel();
              }
            })
            .catch(res => {
              this.$vhMessage({
                message: res.message || `保存失败`,
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
              this.open();
            });
        } else {
          createAdminWebinarProduct({
            goods_ids: this.selectProducts,
            webinar_id: this.$route.params.str
          })
            .then(res => {
              if (res && res.code === 200) {
                this.$vhMessage({
                  message: '保存成功',
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                this.cancel();
                this.$emit('success', res.data);
              } else {
                this.$vhMessage({
                  message: res.msg || '保存失败',
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              }
            })
            .catch(e => {
              // console.log(e);
              this.$vhMessage({
                message: e.msg || '保存失败',
                showClose: true,
                // duration: 0,
                type: 'error',
                customClass: 'zdy-info-box'
              });
            });
        }
      },
      cancel() {
        this.selectProducts = [];
        try {
          this.$refs.elTable.clearSelection();
        } catch (e) {
          console.log(e);
        }
        this.dialogVisible = false;
      },
      readonlyTable() {
        if (this.tableData.length > 0 && this.selectProducts.length > 0) {
          for (let i = 0; i < this.tableData.length; i++) {
            if (this.selectProducts.includes(this.tableData[i].goods_id)) {
              this.$nextTick(() => {
                this.$refs.elTable.toggleRowSelection(this.tableData[i], true);
              });
            }
          }
        }
      }
    }
  };
</script>

<style lang="less" scoped>
  .product-material {
    &-list {
      min-height: 378px;
    }
    &-header {
      padding-bottom: 24px;
      overflow: hidden;
    }
    .subTile {
      color: #8c8c8c;
      font-size: 14px;
      font-weight: 400;
    }
    .product-cover {
      width: 70px;
      height: 70px;
      border-radius: 4px;
      border: 1px solid #e6e6e6;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    &-footer {
      margin-top: 24px;
      display: flex;
      justify-content: space-between;
      .footer-desc {
        line-height: 36px;
        color: #262626;
      }
      b {
        color: #fb2626;
      }
    }
  }
</style>
